import React, { Suspense } from 'react';
import { useAuth0, withAuth0, } from '@auth0/auth0-react';
import LoadingDots from './components/common/LoadingDots';
import Cookies from 'universal-cookie';


interface AuthManagerStateProps { };
interface AuthManagerOwnProps { };
interface AuthManagerDispatchProps { };
interface AuthManagerState { }

type AuthManagerProps = AuthManagerStateProps & AuthManagerOwnProps & AuthManagerDispatchProps;

export class AuthManager extends React.Component<any, AuthManagerState> {
    App = React.lazy(() => import('./App'));
    cookies = new Cookies();

    LoginHandler = () => {
        const { loginWithRedirect } = useAuth0();
        loginWithRedirect({
            redirectUri: window.location.origin + '/',
            appState: window.location
        });

        return (
            <div>
                <LoadingDots />
            </div>
        );
    }

    render() {
        if (this.props.auth0.isLoading) {
            return (
                <div>
                    <LoadingDots />
                </div>
            );
        }
        else if (this.props.auth0.isAuthenticated) {
            return (
                <Suspense fallback={<LoadingDots />}>
                    <this.App />
                </Suspense>
            );
        }
        else {
            // This happens when:
            // 1) the user needs to fully auth and log in
            // - or -
            // 2) the user is authenticated (via cookie) but RM doesn't know it yet
            return <this.LoginHandler />
        }
    }
}

export default withAuth0(AuthManager as any);