// Sourced from: https://tobiasahlin.com/spinkit/ https://github.com/tobiasahlin/SpinKit
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import * as colors from '../../config/colors';

// types
import {
    Styles,
} from '../../config/types';

const bounceDelay = {
    '0%': {
        transform: 'scale(0)',
    },
    '80%': {
        transform: 'scale(0)',
    },
    '100%': {
        transform: 'scale(0)',
    },
    '40%': {
        transform: 'scale(1.0)',
    }
};

export interface LoadingDotsProps {
    /**
     * An optional color for the dots
     */
    color? : string,
    topMargin? : number
};

/**
 * Loading animation. Mesmerizing, isn't it?
 */
function LoadingDots(props : LoadingDotsProps) {
    const styles : Styles = StyleSheet.create({
        spinner: {
            margin: `${props.topMargin ? props.topMargin : 50}px auto 0`,
            width: 70,
            textAlign: 'center',
        },
        spinnerDiv: {
            width: 15,
            height: 15,
            backgroundColor: props.color ? props.color : colors.GRAY_80,
            borderRadius: '100%',
            display: 'inline-block',
            animationName: [bounceDelay],
            animationDuration: '1.4s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',
            animationFillMode: 'both',
        },
        bounce1: {
            animationDelay: '-0.32s',
        },
        bounce2: {
            animationDelay: '-0.16s',
        },
    });
    return (
        <div className={css(styles.spinner)} data-testid={'loadingdots'}>
            <div className={css(styles.spinnerDiv, styles.bounce1)}></div>
            <div className={css(styles.spinnerDiv, styles.bounce2)}></div>
            <div className={css(styles.spinnerDiv, styles.bounce3)}></div>
        </div>
    );
}

export default LoadingDots;
