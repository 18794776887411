// Standard colors used throughout Margarita QA. These are shared with Labeltool.

export const GRABANGO_BLUE = '#6ED3CF';
export const GRABANGO_BLUE_LIGHT =  '#AEE5E3';
export const GRABANGO_BLUE_WASH = '#E5F7F5';
export const GRABANGO_BLUEBERRY = '#31465A';
export const GRABANGO_RED = '#EC222C';
export const GRABANGO_RED_LIGHT = '#ff8880';
export const HEADER_COLOR = GRABANGO_RED;
export const GRABANGO_RED_HIGHLIGHT = '#FF5A3B';
export const GRABANGO_RED_SHADOW = '#C20000';
export const CAUTION_YELLOW = '#FFE215';

// STORE VIS FRUITY COLORS
export const PERSIMMON = '#ed2d0b';  // reddish orange
export const ORANGE = '#f99a49';
export const LEMON = '#c9cc15';
export const LIME = '#7fd422';
export const MINT = '#3cf1a6';
export const BLUEBERRY = '#054667';
export const BLUEBERRY_DARK = '#001B34';
export const PLUM = '#d08ef7';
export const LT_PLUM = '#E2C1F5';
export const RASPBERRY = '#d80a52';

// export const CAL_BLUE_BLUSH = '#f4fdfd';
// export const CAL_BLUE_LIGHT = '#9bd4ed';
// export const CAL_BLUE_MEDIUM = '#0091FF';
export const CAL_BLUE_BLUSH = GRABANGO_BLUE_WASH;
export const CAL_BLUE_LIGHT = GRABANGO_BLUE_LIGHT;
export const CAL_BLUE_MEDIUM = GRABANGO_BLUE;
export const REACT_DATES_GRAY = '#565a5c';

export const LT_GRAY = '#F8F8F8';
export const GRAY = '#EEE';
export const GRAY_80 = '#CCC';
export const MED_GRAY = '#9b9b9b';
export const DARK_GRAY = '#4A4A4A';
export const LABELTOOL_TIMESLIDER_GRAY = '#777F85';

export const WHITE = '#FFF';
export const BLACK = '#222';

export const PERSON_DOTS_MENU_GRADIENT = 'linear-gradient(to top, ' + DARK_GRAY + ' 0%, ' + LABELTOOL_TIMESLIDER_GRAY + ' 100%)';

export const BUTTON_BLUE = '#0091FF';
export const BUTTON_BLUE_HIGHLIGHT = '#69C1FF';
export const BUTTON_BLUE_GHOST = '#E1EFFF';
export const BUTTON_BLUE_SHADOW = '#0064CB';

export const WHITE_TRANSP_95 = 'rgba(235, 235, 235, 0.95)';
export const WHITE_TRANSP_50 = 'rgba(235, 235, 235, 0.5)';
export const BLACK_TRANSP_0 = 'rgba(0, 0, 0, 0.0)';
export const BLACK_TRANSP_10 = 'rgba(0, 0, 0, 0.1)';
export const BLACK_TRANSP_50 = 'rgba(0, 0, 0, 0.5)';
export const BLACK_TRANSP_75 = 'rgba(0, 0, 0, 0.75)';

export const FRUITY_GRADIENT = 'linear-gradient(to right, ' + PERSIMMON + ' 0%, ' + RASPBERRY + ' 100%)';
export const GRAY_TO_WHITE_GRADIENT = 'linear-gradient(to right, ' + GRAY_80 + ' 0%, ' + WHITE + ' 100%)';
export const GRABANGO_GRADIENT = 'linear-gradient(to top, ' + WHITE + ' 0%, ' + GRABANGO_BLUE_LIGHT + ' 100%)';

export const RECEIPT_YELLOW = 'rgba(246,246,178,.65)';
export const RECEIPT_RED = 'rgba(255,30,0,.40)';
export const RECEIPT_BLUE = 'rgba(58,154,241,.40)';

export const PERSON_COLORS = [
    ORANGE,
    LEMON,
    LIME,
    MINT,
    PLUM,
    RASPBERRY,
];

export const MARKER_PURPLE = '#C175D1';
export const MARKER_INDIGO = '#7B70E8';
export const MARKER_BLUE = '#48E1EF';
export const MARKER_GREEN = '#A6C943';
export const MARKER_YELLOW = '#FAE597';
export const MARKER_ORANGE = '#F38329';
export const MARKER_RED = '#F05242';
export const MARKER_PINK = '#F978D5';
export const SOUVENIR_COLORS = [
    MARKER_PURPLE,
    MARKER_INDIGO,
    MARKER_BLUE,
    MARKER_GREEN,
    MARKER_YELLOW,
    MARKER_ORANGE,
    MARKER_RED,
    MARKER_PINK,
];
export const PLANOGRAM_MARKER_COLORS = [
    MARKER_PURPLE,
    MARKER_INDIGO,
    MARKER_BLUE,
    MARKER_GREEN,
    MARKER_YELLOW,
    MARKER_PINK,
];

export const TRACK_COLORS = ['#95ED8F', '#B47BF4', '#FFB26A', '#7EE8F5', '#FB9A99', '#FFFF33', '#DECBE4', '#FA8AD7', '#9F5BD8', '#D95F59', '#5872D8', '#E41A1C'];
// export const TRACK_COLORS = ['#6DD400', '#95ED8F', '#FFB26A', '#7EE8F5', '#FB9A99', '#FFFF33', '#DECBE4', '#FA8AD7', '#9F5BD8', '#D95F59', '#5872D8', '#E41A1C'];

export const VISIT_STATUS_COLORS : { [key : string] : string } = {
    start_finding_start: '#EAF2D7',
    tracking_start: '#EAF2D7',
    manual_person_dots_start: '#EAF2D7',
    track_validation_start: '#EAF2D7',
    start_finding_end: '#EAF2D7',
    tracking_end: '#EAF2D7',
    manual_person_dots_end: '#EAF2D7',
    track_validation_end: '#EAF2D7',
    tracking_init_start: '#EAF2D7',
    tracking_init_end: '#EAF2D7',
    detections_start: '#EAF2D7',
    detections_end: '#EAF2D7',
    automated_tracking_start: '#EAF2D7',
    automated_tracking_end: '#EAF2D7',
    manual_tracking_start: '#EAF2D7',
    manual_tracking_end: '#EAF2D7',
    event_detection_start: '#B3DEE2',
    product_identification_start: '#B3DEE2',
    payments_processing: '#B3DEE2',
    event_detection_end: '#B3DEE2',
    product_identification_end: '#B3DEE2',
    receipt_review_start: '#CF8E80',
    receipt_review_end: '#CF8E80',
    payments_success: '#7EC55D',
    completed: '#7EC55D',
    rejected: '#EF2D56',
    deleted: '#EF2D56',
    payments_failure_hard_decline: '#EF2D56',
    payments_failure_max_retries: '#EF2D56',
    aborted: '#FFBD00',
    payments_pending: '#FFBD00',
    payments_retry: '#FFBD00',
    receipt_pricing_failed: '#EF2D56',
};

export const REFUND_STATUS_COLORS : { [key : string] : string} = {
    created: '#7EE8F5',
    approved: '#B3DEE2',
    rejected: '#EF2D56',
    completed: '#7EC55D',
    new: '#7EE8F5',
};

export const VISIT_PAYMENT_STATUS_COLORS : { [key : string] : string} = {
    in_progress: '#B3DEE2',
    success: '#7EC55D',
    soft_decline: '#EF2D56',
    hard_decline: '#EF2D56',
};
