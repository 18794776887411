import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import AuthManager from './AuthManager';

const AUTH0_DOMAIN = (window as any).MARGARITA_QA_CONFIG.auth0_domain;
const AUTH0_CLIENT_ID = (window as any).MARGARITA_QA_CONFIG.auth0_client_id;
const AUTH0_AUDIENCE = (window as any).MARGARITA_QA_CONFIG.auth0_audience;


ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={window.location.origin + '/margaritaqa/'}
    audience={AUTH0_AUDIENCE}
  >
    <React.StrictMode>
      <AuthManager/>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);
